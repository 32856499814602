import React, { useEffect, useState } from 'react'
import { fetchEvents } from '../../Redux/Actions/Actions'
import PictureCollectionListItem from '../PictureCollectionListItem/PictureCollectionListItem'

const PictureCollectionList = ({setLoad}) => {
    const [events,setEvents] = useState([])
   
  
  
  
    useEffect(async ()=>{
      var arr = await fetchEvents()
      setEvents(arr);
      setLoad(false)
    },[])
    return (
        <div className="pictureCollectionList row mt-5">
        {
            events.map(e =>     <PictureCollectionListItem event={e} key={e.id}/>)
        }
        
        </div>
    )
}

export default PictureCollectionList
