import React from 'react'
import Heading from '../../Components/Heading/Heading';

const NotFound = ({setLoad}) => {
    React.useEffect(()=>{
        var t = ()=> setTimeout(()=> setLoad(false),500)   
        t();
        return ()=>{
            clearTimeout(t)
        }
   },[])
    return (
        <div className="w-100 h-100 d-flex justify-content-center ">
            <Heading fontSize={50} className="mt-5">
                404 Not Found
            </Heading>
        </div>
    )
}

export default NotFound
