import React from 'react'
import "./Button.css"
const Button = (props) => {
    var {style,className,...restProps} = props;
    return (
        <button className={`btn-container ${className}`} style={{...style}}
       { ...restProps}
        >
            
        </button>
    )
}

export default Button
