export var getSiblings = function (elem) {
  // Setup siblings array and get the first sibling
  var siblings = [];
  var sibling = elem.parentNode.firstChild;

  // Loop through each sibling and push to the array
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }

  return siblings;
};

export const API_TOKEN = "$15348%261&8#123";
export const _URL = "https://www.kda.gkp.pk/KHDA";

export var pagesCalculator = (numberOfItems) => {
  var d = numberOfItems / 10;
  return Math.ceil(d);
};
export var convertObjectToArray = (obj) => {
  var result = Object.keys(obj).map((key) => obj[key]);
  return result;
};
export var getCatName = (catId, array) => {
  if (array.length) {
    var desired = array.filter((el) => el.id == catId);
    if (desired.length) return desired[0].Name;
  }
  return "";
};

export var createChunks = (array, chunkLength) => {
  var chunks = [];
  for (let i = 0; i < array.length; i = i + chunkLength) {
    var temp = [];
    for (let j = i + (chunkLength - 1); j >= i; j--) {
      if (array[j]) {
        temp.push(array[j]);
      }
      if (j === i) {
        chunks.push([...temp]);
      }
    }
  }
  return chunks;
};
export var arrCategorization = (array) => {
  var objCheck = Array.isArray(array);

  if (!objCheck) {
    array = convertObjectToArray(array);
  }
  var categorizedarray = [];
  var exist = null;
  array.forEach((arr) => {
    exist = categorizedarray.some((cp) => cp.Status === arr.Status);
    if (!exist) {
      categorizedarray.push({
        Status: arr.Status,
        array: [arr],
      });
    } else {
      categorizedarray.map((cp) => {
        if (cp.Status === arr.Status) {
          return {
            ...cp,
            array: cp.array.push(arr),
          };
        } else {
          return cp;
        }
      });
    }
  });
  return categorizedarray;
};
export var projectDigg = (status) => {
  switch (status) {
    case "c":
      return "completed";
    case "o":
      return "ongoing";

    case "f":
      return "future";
    case "m":
      return "mega";
    case "h":
      return "hschemes";

    default:
      return "completed";
  }
};
export var getDepartmentName = (dptId, array) => {
  if (array.length) {
    var desired = array.filter((el) => el.id == dptId);

    if (desired.length) return desired[0].Name;
  }
  return "";
};
export var getApiMessage = (status) => {
  if (status === 200) {
    return {
      success: "Successfull",
    };
  } else if (status === 201) {
    return {
      success: "Submitted!",
    };
  } else if (status === 202 || status === 204) {
    return {
      success: "Updated Successfully",
    };
  } else if (status === 500) {
    return {
      error: "Something went wrong",
    };
  } else if (status === 400) {
    return {
      error: "Something went wrong",
    };
  } else if (status === 404) {
    return {
      error: "Not Found",
    };
  }
  return {};
};
export function ValidateEmail(mail) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }

  return false;
}
export var keyWords = [{
  key:"Projects",
  link:"/projects/all/1"
}
,
{
  key:"Completed Projects",
  link:"/projects/completed/1"
}
,
{
  key:"Future Projects",
  link:"/projects/future/1"
},
{
  key:"Housing Schemes",
  link:"/projects/hschemes/1"
},
{
  key:"Events",
  link:"/about/events"
},
{
  key:"Picture Gallery",
  link:"/about/picture_gallery"
},
{
  key:"Downloads",
  link:"/about/downloads/1"
},
{
  key:"Advertisements"
  ,link:"/advertisements/1"
}
,
{
  key:"Tenders",
  link:"/tenders/1"
}
,
{
  key:"Contact Us",
  link:"/contact"
}
,
{
  key:"About Peshawer",
  link:"/about/about-peshawar"
}
]