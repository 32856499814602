import React, { useEffect, useState } from 'react'
import { Form, Pagination, Table } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading'
import { fetchProjects } from '../../Redux/Actions/Actions';
import { arrCategorization, pagesCalculator , projectDigg} from '../../Utility';

const Projects = (props) => {
    var {match:{params:{type,pageNumber}},counts = {},setLoad} = props
    const history = useHistory()
    var linkchanger = (link)=>{
        history.push(link)
    }
    const [tableItems,setTableItems] = useState([])
    const [pages,setPages] = useState(1)
    useEffect( ()=>{
      var countNumber = 0
        for (const key in counts) {
            countNumber = countNumber + counts[key]
        }

      var numberOfPages = pagesCalculator(countNumber) ? pagesCalculator(countNumber) : 1
      
        var temp =[]
        for (let number = 1; number <= numberOfPages; number++) {
        
          temp.push(
            <Pagination.Item key={number} active={number === Number(pageNumber)}>
                <Link to={`/projects/${type}/${number}`}>
                {number}
                </Link>
            </Pagination.Item>,
          );
        }
        setPages(temp)
      
        
      },[pageNumber,counts])
  
  
      useEffect(async ()=>{
        var arr = await fetchProjects(pageNumber)
       if(arr.length){
        if(type !== "all"){
          arr = arrCategorization(arr)
          arr = arr.map(el => ({...el,Status: projectDigg(el.Status)}))
          var temp = arr.filter(el => el.Status === type)
          if(temp.length){
            setTableItems(temp[0].array)
          }
        }
        else{
            setTableItems(arr)
        }
       }

      
    //       if(arr.length){
              
    //     if(type !== "all"){
    //       var temp = arr.filter(el   => el.Status === type && el.array )
    //       console.log(temp)
    //  if(temp.length){
    //   setTableItems(temp[0].array);
    //  }
    
    //     }
    //     else{
    //       temp = arr.map(el => el.array)
    //       console.log(temp)
    //     setTableItems(temp);

    //     }
    //       }
        
        setLoad(false)
      },[pageNumber])
  
   
    return (
        <div>
          
            <div className="p-5">
            <Heading fontSize={30} className="hhh">
                    Projects
                </Heading>
                <div className="row m-0 link-checks">
               <div className="col-md-4">
            
               <Heading className="">
                    Completed Projects
                    </Heading>
                   <Form.Check onClick={()=> linkchanger("/projects/completed/"+pageNumber)} name="myLink" type="radio" defaultChecked={type === "completed" ? true : false}/>
            
               </div>
               <div className="col-md-4">
               <Heading className="">
                     Ongoing Projects
                    </Heading>
                   <Form.Check onClick={()=> linkchanger("/projects/ongoing/"+pageNumber)} name="myLink" type="radio" defaultChecked={type === "ongoing" ? true : false}/>
               </div>
               <div className="col-md-4">
               <Heading className="ml-5">
                  Future Planned Projects
                    </Heading>
                   <Form.Check onClick={()=> linkchanger("/projects/future/"+pageNumber)} name="myLink" type="radio" defaultChecked={type === "future" ? true : false}/>
               </div>
               <div className="col-md-4">
               <Heading className="">
                 Mega Projects
                    </Heading>
                   <Form.Check onClick={()=> linkchanger("/projects/mega/"+pageNumber)} name="myLink" type="radio" defaultChecked={type === "mega" ? true : false}/>
               </div>
               <div className="col-md-4">
               <Heading className="">
                   Housing Schemes
                    </Heading>
                   <Form.Check onClick={()=> linkchanger("/projects/hschemes/"+pageNumber)} name="myLink" type="radio" defaultChecked={type === "hschemes" ? true : false}/>
               </div>
               <div className="col-md-4">
               <Heading className="">
                   All
                    </Heading>
                   <Form.Check onClick={()=> linkchanger("/projects/all/"+pageNumber)} name="myLink" type="radio" defaultChecked={type === "all" ? true : false}/>
               </div>
           </div>
        
            </div>
            <Table  bordered >
        <thead>
          <tr>
            <th>S#</th>
            <th>Title</th>
            <th >Detail</th>
            <th>% Completed</th>
            <th>Project Cost</th>
          
          </tr>
        </thead>
        <tbody>
       
        {
          tableItems.length ?  tableItems.map((p,i)=>  <tr key={i}>
          <td>{++i}</td>
          <td>{p.Title}</td>
          <td>
        {p.Details}
          </td>
          <td>{p.Per_Complete}</td>
          <td>{p.Cost}</td>
      
        </tr>):""
        }
        
        
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
             <Pagination  className="mt-5 " size="lg">{pages}</Pagination>

                </div>
        </div>
    )
}
var mapState = (state) =>({
  counts : state.counts.projectsCount
})
export default connect(mapState)(withRouter(Projects))
