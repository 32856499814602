import React from 'react'
import { Link } from 'react-router-dom'
import Heading from '../../Components/Heading/Heading'
import Paragraph from '../../Components/Paragraph/Paragraph';
import img from "../../assets/img/akber.png"
import "./Home.css"
import StyledButton from '../../Components/StyledButton/StyledButton';
import mk from "../../assets/img/mk.png";
import secLG from "../../assets/img/secLG.jpg";
import director from "../../assets/img/director.jpeg"
const Home = ({setLoad}) => {
    React.useEffect(()=>{
       var t  = ()=> setTimeout(()=>setLoad(false) ,400) ;
       t();
       return ()=> {
           clearTimeout(t)
       } 
    },[])
    return (
        <div className="home-page">
          <div className=" p-5">
          <Heading fontSize={30} className="hhh">
                    About KDA
                </Heading>
                <Paragraph className="mt-5">
                Kohat Development Authority Kohat was established in the year 1979. The KDA comprises two Phases i.e. Phase-I, Phase-II and Phase-II Extension. The Phase-I and Phase-II are fully developed.Establishment of Township Schemes and to execute developmental works assigned to it by the Federal/Provincial Governments, Local Bodies or any  other agencies as a deposit work .To combat homelessness in termsof facilitating people in construction of homes, so as to save agricultural land from depletion by construction of  homes.To provide basic facilities to the residentsof Kotal Township being in the domain of this Authority. To acquire land for expansion of the township as per requirements of the  people of the area.
            
                </Paragraph>
                <Link to="/about" className="read-more">Read more</Link>
                <Heading fontSize={30} className="hhh mt-5">
                    Administrative Heads
                </Heading>
                <div className="row mt-5">
                    <div className="col-md-3 text-center">
                        <img src={mk} alt="" width="100%" />
                        <Heading className="my-2">
                        Mahmood Khan


                        </Heading>
                        <Paragraph>
                        Chief Minister KPK

                        </Paragraph>
                    </div>
                    <div className="col-md-3 text-center">
                        <img src={img} alt="" width="100%" />
                        <Heading className="my-2">
                        Akbar Ayub Khan

                        </Heading>
                        <Paragraph>
                        Local Govt Minister

                        </Paragraph>
                    </div>
                    <div className="col-md-3 text-center">
                        <img src={secLG} alt="" width="100%" />
                        <Heading className="my-2">
                        Mr. Shakeel Ahamd Mia

                        </Heading>
                        <Paragraph>
                        Local Govt Minister

                        </Paragraph>
                    </div>
                    <div className="col-md-3 text-center">
                        <img src={director} alt="" width="100%" />
                        <Heading className="my-2">
                        Khan Azad khan



                        </Heading>
                        <Paragraph>
                        Project Director

                        </Paragraph>
                    </div>
                    <div className="col-12 mt-5">
                    <Heading fontSize={30} className="hhh">
                    Message from   Project Director

                </Heading>
                        <Paragraph className="mt-5">
                        Thank you for visiting our website. I encourage you to navigate throughout our website. You will learn about our organization, our mission and information about our services we provide, as well as accessing complaint management system of Water and Sanitation department. Whether you are a resident of Peshawar, Hayatabad or navigating through our website from across the world,

                        </Paragraph>
                    </div>
               
                </div>
          </div>
        </div>
    )
}

export default Home
