import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { API_TOKEN, _URL } from '../../Utility'
import Heading from "../Heading/Heading"
import "./MySlider.css"
const MySlider = () => {
   const [sliderContent,setSliderContent] = useState([]);
   useEffect(async ()=>{
         try {
            var query = await fetch(`${_URL}/api/Banner`,{
               method:"get",
               headers:{
                  Token: API_TOKEN
               }
            })
            var data = await query.json();
           if(query.status === 200){
              setSliderContent(data)
           }
         } catch (error) {
            console.log(error.message)
         }
   },[])

    return (
     <div className="home-slider">
            {
               sliderContent.length ?  <Carousel  controls={false} fade={true}> { sliderContent.map((img,i) =>  
 
  
                  <Carousel.Item key={i}>
                 
                  <img src={img.Image} alt=""/>
               
                  </Carousel.Item>)} 

                  </Carousel> : ""
                 
               
            }
     </div>
    )
}

export default MySlider
