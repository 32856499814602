import { API_TOKEN, convertObjectToArray, _URL } from "../../Utility";

export var fetchAdverts = async (page = 1) => {
  try {
    var query = await fetch(`${_URL}/api/Advertisements/${page}`, {
      method: "get",
      headers: {
        Token: API_TOKEN,
      },
    });
    var data = await query.json();

    if (query.status === 200) {
      if (!Array.isArray(data)) {
        data = convertObjectToArray(data);
      }
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};
export var fetchDownloads = async (page) => {
  try {
    var query = await fetch(`${_URL}/api/Downloads/${page}`, {
      method: "get",
      headers: {
        Token: API_TOKEN,
      },
    });
    var data = await query.json();

    if (query.status === 200) {
      if (!Array.isArray(data)) {
        data = convertObjectToArray(data);
      }
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};
export var fetchEvents = async () => {
  try {
    var query = await fetch(`${_URL}/api/Event`, {
      method: "get",
      headers: {
        Token: API_TOKEN,
      },
    });
    var data = await query.json();

    if (query.status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

export var fetchSingleEvent = async (id) => {
  try {
    var query = await fetch(`${_URL}/api/Event/${id}`, {
      method: "get",
      headers: {
        Token: API_TOKEN,
      },
    });
    var data = await query.json();

    if (query.status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }
};

export var fetchProjects = async (page = "1") => {
  try {
    var query = await fetch(`${_URL}/api/Projects/${page}`, {
      method: "get",
      headers: {
        Token: API_TOKEN,
      },
    });
    var data = await query.json();

    if (query.status === 200) {
      if (!Array.isArray(data)) {
        data = convertObjectToArray(data);
      }
      return data
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return 400;
  }
};
export var fetchProjectsCounts = async () => {
   
  try {
    var query = await fetch(`${_URL}/api/ProjectsCount`, {
      method: "get",
      headers: {
        Token: API_TOKEN,
      },
    });
    var data = await query.json();

    if (query.status === 200) {
      return data;
    }
    return {
      c: 0,
      o: 0,
      f: 0,
      m: 0,
      h: 0,
    };
  } catch (error) {
    console.log(error);
    return {
        c: 0,
        o: 0,
        f: 0,
        m: 0,
        h: 0,
      };
  }
};

export var fetchAllTenders = async  (page = "1")=>{
    try {
        var query = await  fetch(`${_URL}/api/Tenders/${page}`,{
            method:"get",
            headers:{
                Token:API_TOKEN
            }
        })
        var data = await query.json();
     
        if (query.status === 200) {
            if (!Array.isArray(data)) {
              data = convertObjectToArray(data);
            }
            return data
          } else {
            return [];
          }
    } catch (err) {
        console.log(err)
    }
}

export var fetchDepartments =async () =>{
    try {
        var query = await  fetch(`${_URL}/api/Department`,{
            method:"get",
            headers:{
                Token:API_TOKEN
            }
        })
        var data = await query.json();
        
        if(query.status === 200){
            return data
        }
        else {
                return []
        }
    
    } catch (error) {
        return []
    }
}
export var submitContactForm =async (obj) =>{
  var json = JSON.stringify(obj)
  try {
      var query = await  fetch(`${_URL}/api/Feedback`,{
          method:"post",
          headers:{
              Token:API_TOKEN,
              "Content-type":"application/json"
          },
          body:json
      })
      var data = await query.json();
      
     return query.status
  
  } catch (error) {
      console.log(error);
      return query.status
  }
}


