import React from "react";
import Paragraph from "../Paragraph/Paragraph";

const AboutContent = ({setLoad}) => {
  React.useEffect(()=>{
      setLoad(false)
  },[])
  return (
    <div>
      <Paragraph>
        In 2001 the Local Area Authorities were devolved to respective City /
        District Government under Local Government Ordinance 2001 and UPO - 1978
        was repealed through an Ordinance in May 2002 In 2012 all the Local Area
        Authorities under section 80 were placed under the administrative
        control of Local Government & Rural Development Department Government of
        Khyber Pakhtunkhwa. In 2013 All the Local Area Authorities under section
        121(2) retained in Local Government, Elections & Rural Development
        Department of Khyber Pakhtunkhwa & shall continue to exercise
        administrative controls over Local Area Authorisation 2001 the Local
        Area Authorities were devolved to respective City / District Government
        under Local Government Ordinance 2001 and UPO - 1978 was repealed
        through an Ordinance in May 2002. In 2012 all the Local Area Authorities
        under section 80 were placed under the administrative control of Local
        Government & Rural Development Department Government of Khyber
        Pakhtunkhwa. In 2013 All the Local Area Authorities under section 121(2)
        retained in Local Government, Elections & Rural Development Department
        of Khyber Pakhtunkhwa & shall continue to exercise administrative
        controls over Local Area Authorities.
      </Paragraph>
    </div>
  );
};

export default AboutContent;
