import { faGreaterThan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebookF,faSkype,faLinkedinIn} from "@fortawesome/free-brands-svg-icons"
import React from 'react'
import { Link } from 'react-router-dom'
import Heading from '../Heading/Heading'
import Paragraph from '../Paragraph/Paragraph'
import StyledButton from '../StyledButton/StyledButton'
import "./Footer.css"
const Footer = () => {
    return (
        <div className="footer row mx-0 ">
            <div className="col-md-3 justify-content-end d-flex px-0 mt-5">
             <Link to="/contact">
             <StyledButton className=" align-self-start">
                    Online Complaint
                </StyledButton>
             </Link>
            </div>
            <div className="col-md-9 px-0 mt-5">
              <div className="d-flex footer-link-part justify-content-center h-100">
                  <div className="usefull-links">
                      <Heading style={{color:"var(--lightgrey)"}} className="mb-5 hhh" fontSize={22} fontWeight={400}>
                          Useful Links
                      </Heading>
                      <div className="usefull-link-item">
                          <Link to="/about">
                          <Paragraph className="p" fontSize={16}>
                              ABOUT US <FontAwesomeIcon icon={faGreaterThan}/>
                          </Paragraph>
                          </Link>
                      </div>
                      <div className="usefull-link-item">
                      <Link to="/tenders/1">
                          <Paragraph className="p" fontSize={16}>
                          TENDERS <FontAwesomeIcon icon={faGreaterThan}/>
                          </Paragraph>
                          </Link>
                      </div>
                      <div className="usefull-link-item">
                      <Link to="/about/downloads/1">
                          <Paragraph className="p" fontSize={16}>
                          DOWNLOADS <FontAwesomeIcon icon={faGreaterThan}/>
                          </Paragraph>
                          </Link>
                      </div>
                      <div className="usefull-link-item">
                      <Link to="/contact">
                          <Paragraph className="p" fontSize={16}>
                              CONTACT US <FontAwesomeIcon icon={faGreaterThan}/>
                          </Paragraph>
                          </Link>
                      </div>
                  
                  </div>
                  <div className="contact-us">
                  <Heading style={{color:"var(--lightgrey)"}} className="mb-5 hhh" fontSize={22} fontWeight={400}>
                            Contact Us
                          </Heading>
                          <Paragraph>
                              Phase, V <br/>
                              Hayatabad , Peshawar , Pakistan <br/>
                              Phone: 091-921706-29 <br/>
                              Fax: 091-92170300 <br/>
                              Email: pdahst@gmail.com
                          </Paragraph>
                  </div>
              </div>
            </div>
            <div className="footer-bottom">
                <Paragraph className="text-dark">
                    2020 Copyrights All Right Reserved.<Link> <span style={{color:"var(--color-secendory)"}}>Privacy Policy</span></Link> | <Link> <span style={{color:"var(--color-secendory)"}}>Terms of service</span></Link>
                </Paragraph>
                <Paragraph className="footer-brands">
                    <Link>
                    <FontAwesomeIcon icon={faFacebookF}/>
                    </Link>
                    <Link>
                    <FontAwesomeIcon icon={faSkype}/>
                    
                    </Link>
                    <Link>
                    <FontAwesomeIcon icon={faLinkedinIn}/>
                    
                    </Link>

                </Paragraph>
            </div>
        </div>
    )
}

export default Footer
