export const SET_DEPARTS = "SET_DEPARTS";

var departReducer  = (state=[],actions) =>{
    switch (actions.type) {
        case SET_DEPARTS:
        
           return actions.payload.departs
    
        default:
            return state
    }
}

export default departReducer