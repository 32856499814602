import { useEffect } from 'react';
import {  connect } from 'react-redux';
import './App.css';
import Header from "./Components/Header/Header"
import PagesWrapper from "./Components/PagesWrapper/PagesWrapper";
import { fetchDepartments, fetchProjectsCounts } from './Redux/Actions/Actions';
import { setCats } from './Redux/Catagories/catAction';
import { setCounts , setProjectsCounts } from './Redux/Counts/countActions';
import { setDeparts } from './Redux/departments/departActions';
import { API_TOKEN, _URL } from './Utility';
function App({
  setCounts,
setCats,setProjectsCounts,
setDeparts

}) {
useEffect(()=>{
 console.clear() 
})
  useEffect(async()=>{

    try {
      var query = await fetch(`${_URL}/api/Count`,{
          method:"get",
          headers:{
              Token:API_TOKEN
          }
      });
      var data = await query.json();
   
      if(query.status === 200){
          setCounts(data)
      }
} catch (error) {
  console.log(error)
}

fetchCatagories()

var data = await fetchProjectsCounts();

setProjectsCounts(data)
var departs = await fetchDepartments()
setDeparts(departs)
console.clear()
  },[])

   var fetchCatagories = async ()=>{
    try{
        var query = await fetch(`${_URL}/api/Category`,{
            method:"get",
            headers:{
                Token:API_TOKEN
            }
        });
        var data = await query.json()
       
        if(query.status === 200){
          setCats(data)
        }
    }
    catch(err){
        console.log(err)
    }
}
  return (
   <div className="app-wrapper">
      <div className="container-custom px-5">
     <div className="row">
     <Header/>

     <PagesWrapper/>
     </div>
      </div>
   </div>
  );
}
var actions = {
  setCounts,
  setCats,setProjectsCounts,setDeparts
}
export default connect(null,actions)(App);
