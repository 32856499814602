import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Heading from "../../Components/Heading/Heading";
import Paragraph from "../../Components/Paragraph/Paragraph";

const AboutDirectorates = ({ setLoad }) => {
  // <FontAwesomeIcon  icon={faAngleRight}/>

  React.useEffect(() => {
    setLoad(false);
  }, []);
  const [number, setNumber] = useState(0);
  const history = useHistory();

  return (
    <div>
      <Paragraph fontSize={30} className="my-5">
        Peshawar Development Authority comprises upon following Functional
        Groups
      </Paragraph>
      <Paragraph className="ml-5">1. Urban Development</Paragraph>
      <Paragraph className="ml-5">2. Traffic Engineering Wing</Paragraph>
      <Paragraph className="ml-5">3. Perks and Horticulture Wing</Paragraph>
      <Paragraph fontSize={30} className="my-5">
        List of Directorates and Sections
      </Paragraph>
      {!number ? (
        <div className="ml-5 direcs">
          <>
            {" "}
            <Paragraph onClick={() => setNumber(1)}>
              1. <span className="bb  ">Directorate of Administration</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </Paragraph>
            <Paragraph onClick={() => setNumber(2)}>
              2.{" "}
              <span className="bb  ">
                Finance Directorate
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>
            <Paragraph onClick={() => setNumber(3)}>
              3.{" "}
              <span className="bb  ">
                Engineering Directorate
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>
            <Paragraph onClick={() => setNumber(4)}>
              4.{" "}
              <span className="bb  ">
                Estate Management Directorate
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>
            <Paragraph onClick={() => setNumber(5)}>
              5.{" "}
              <span className="bb  ">
                Land Development/ Acquisition Collector Directorate
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>
            <Paragraph onClick={() => setNumber(6)}>
              6.{" "}
              <span className="bb  ">
                Planning and Development Directorate
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>
            <Paragraph onClick={() => setNumber(7)}>
              7.{" "}
              <span className="bb  ">
                Building Control Directorate
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>
            <Paragraph onClick={() => setNumber(8)}>
              8.{" "}
              <span className="bb  ">
                Perks and Horticulture Directorate
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>
            <Paragraph onClick={() => setNumber(9)}>
              9.{" "}
              <span className="bb  ">
              Legal Wing
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </Paragraph>{" "}
          </>
        </div>
      ) : number === 1 ? (
        <Detail1 backFunction={() => setNumber(0)} />
      ) : number === 2 ? (
        <Detail2 backFunction={() => setNumber(0)} />
      ) : number === 3 ? (
        <Detail3 backFunction={() => setNumber(0)} />
      ) : number === 4 ? (
        <Detail4 backFunction={() => setNumber(0)} />
      ) : number === 5 ? (
        <Detail5 backFunction={() => setNumber(0)} />
      ) : number === 6 ? (
        <Detail6 backFunction={() => setNumber(0)} />
      ) : number === 7 ? (
        <Detail7 backFunction={() => setNumber(0)} />
      ) : number === 8 ? (
        <Detail8 backFunction={() => setNumber(0)} />
      ) : number === 9 ? (
        <Detail9 backFunction={() => setNumber(0)} />
      ) : (
        ""
      )}
    </div>
  );
};

var Detail1 = ({ backFunction }) => {
  return (
    <React.Fragment>
      <Paragraph className="back" onClick={backFunction}>
        <FontAwesomeIcon icon={faAngleLeft} />
        See List
      </Paragraph>
      <Heading>Directorate of Administration</Heading>
      <Paragraph className="my-3">
        <b>Functions of the Directorate:</b>
      </Paragraph>
      <Paragraph>
        Directorate of Administration was setup with an objective to
        facilitate/coordinate inter directorate working for effective and
        expeditious disposal of business. The Directorate acts as a focal point.
        Information required by various Government Organizations on issue
        pertaining to PDA is collected from respective Directorates, Divisions,
        Cells, and Wings, collated in this Directorate, and then sent to the
        concerned Government Department or other agencies.
      </Paragraph>
      <Paragraph className="mt-4">
        <ul>
          <li>To coordinate among different Directorates of PDA.</li>
          <li>To deal with the matters of employees of PDA in all grades.</li>
        </ul>
      </Paragraph>
      <Paragraph className="mt-5">
        It includes: <br /> <b>Appointments</b>
      </Paragraph>
      <Paragraph className="mt-4">
        <ul>
          <li>Initial Recruitment</li>
          <li>Promotions</li>
          <li>Transfer / Posting / Deputation</li>
          <li>Move Over</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <b>Disciplinary Cases / Enquiries</b>
      </Paragraph>
      <Paragraph className="mt-4">
        <ul>
          <li>
            To get the policies, plans and programs implemented through close
            liaison with PSOs.
          </li>
          <li>
            To obtain reports from concerned Directorates on the reference
            received from the Government and other agencies and send replies for
            compilation.
          </li>
          <li>
            To deal with the monthly reports on Annual Development Program for
            Planning and Development Board.
          </li>
          <li>
            Allocation of the vehicles and residences to the officers of the
            U.D. Wing, PDA.
          </li>
          <li>
            To arrange meetings of the Director General and keep record thereof.
          </li>
          <li>
            To coordinate arrangements for the celebration of National events.
          </li>
          <li>
            To coordinate arrangements regarding visits for foreign delegations
            and other VIP.
          </li>
          <li>To act as liaison officer between Mohtasib and PDA.</li>
          <li>To do all other jobs assigned by the Director General.</li>
        </ul>
      </Paragraph>
    </React.Fragment>
  );
};

var Detail2 = ({ backFunction }) => {
  return (
    <React.Fragment>
      <Paragraph className="back" onClick={backFunction}>
        <FontAwesomeIcon icon={faAngleLeft} />
        See List
      </Paragraph>
      <Heading>Finance Directorate</Heading>
      <Paragraph className="my-3">
        <b>Functions of the Directorate:</b>
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            Make payments on account of Development / Non-Development
            expenditures in accordance with the prescribed financial rules etc.
            after pre-audit by the Local Fund Audit Department.
          </li>
          <li>
            To operate bank accounts as per prescribed procedure, and prepare
            cash flow statements for on-going and new projects.
          </li>
          <li>
            To prepare annual budget, for approval by the Governing Body of RDA
            and City District Government also to ensure the effectiveness of the
            budget.
          </li>
          <li>To get the funds released from Government.</li>
          <li>
            To prepare and submit all accounting reports and statements to meet
            legal and management requirements, as well as the Government and
            other agencies.
          </li>
          <li>
            To liaise with external & internal auditors, ensure timely
            submission of answers to their observations and coordinate with
            other Directorates, in this respect.
          </li>
          <li>
            To ensure that all collection and payments are properly recorded,
            handled and accounted for.
          </li>
        </ul>
      </Paragraph>
    </React.Fragment>
  );
};

var Detail3 = ({ backFunction }) => {
  return (
    <React.Fragment>
      <Paragraph className="back" onClick={backFunction}>
        <FontAwesomeIcon icon={faAngleLeft} />
        See List
      </Paragraph>
      <Heading>Engineering Directorate</Heading>
      <Paragraph className="my-3">
        <b>Functions of the Directorate:</b>
      </Paragraph>
      <Paragraph className="mt-4">
        <ul>
          <li>Execution of Development Works in Housing Schemes.</li>
          <li>Construction of Roads, Flyover & Under pass in the City.</li>
          <li>
            Construction of Commercial Office Building, Residences, Flats,
            Shops, Educational Building & Sports Facilities.
          </li>
          <li>Maintenance of PDA Buildings etc.</li>
        </ul>
      </Paragraph>
    </React.Fragment>
  );
};

var Detail4 = ({ backFunction }) => (
  <React.Fragment>
    <Paragraph className="back" onClick={backFunction}>
      <FontAwesomeIcon icon={faAngleLeft} />
      See List
    </Paragraph>
    <Heading>Estate Management Directorate</Heading>
    <Paragraph className="my-3">
      <b>Functions of the Directorate:</b>
    </Paragraph>
    <Paragraph className="mt-4">
      <ul>
        <li>
          Sale of vacant residential and commercial plots and constructed units
          in PDA Schemes, through open auction.
        </li>
        <li>
          Allotment of plots earmarked for utility sites in PDA Schemes, to
          applicant organization and Government Departments, on reserve price.
        </li>
        <li>
          Allotment of plots to specified groups, laid down in Disposal of Land
          by Development Authorities (Regulation) Act.1998.
        </li>
        <li>
          Protection of PDAs immovable properties and removal of encroachments
          on vacant plots/PDA's unsold constructed units/roads in the schemes of
          PDA.
        </li>
        <li>Recovery of cost/price of sold and allotted plots.</li>
        <li>
          Delivery of possession of allotted/auctioned/exempted plots, to their
          rightful owners.
        </li>
        <li>To allow transfer of plots.</li>
        <li>To allow extension in building period of plots.</li>
        <li>
          To issue No Objection Certificate (N.O.C) for obtaining loan for house
          building from House Building Finance Corporation and Banks.
        </li>
        <li>To issue N.O.C for obtaining Sui Gas connection.</li>
        <li>
          To execute sale deeds of plots. After execution of sale deed rights
          are transferred to and vest in allottee proprietary.
        </li>
      </ul>
    </Paragraph>
  </React.Fragment>
);

var Detail5 = ({ backFunction }) => (
  <React.Fragment>
    <Paragraph className="back" onClick={backFunction}>
      <FontAwesomeIcon icon={faAngleLeft} />
      See List
    </Paragraph>
    <Heading>Land Development / Acquisition Collector Directorate</Heading>
    <Paragraph className="my-3">
      <b>Functions of the Directorate:</b>
    </Paragraph>
    <Paragraph className="mt-4">
      <ul>
        <li>
          To sign agreements for exemption of all types of plots in accordance
          with the instructions, orders and policy in force, from time to time.
        </li>
        <li>
          To sign exchange deeds in respect of plots in accordance with the
          instructions, orders and policy in force, from time to time.
        </li>
        <li>
          To grant extensions in building periods, on payment of prescribed
          surcharge, in accordance with the instructions, orders and policy in
          force from time to time.
        </li>
        <li>
          To cancel the exemption of any plot for violation of any term or
          condition of the Agreement in respect of such plot and to forfeit the
          whole or part of the earnest money in respect thereof.
        </li>
        <li>
          To revive the exemption of any plot in accordance with the
          instructions, orders and policy in force from time to time.
        </li>
        <li>
          To allow transfers of exempted plots in accordance with the
          instructions, orders and policy in force, from time to time.
        </li>
        <li>
          To permit extensions in the periods for payments, of the Authority's
          dues, subject to payment of interest at prescribed rates, and upto six
          months in all.
        </li>
        <li>
          To sanction rebates of development charges in accordance with the
          instructions, orders and policy in force from time to time.
        </li>
      </ul>
    </Paragraph>
  </React.Fragment>
);
var Detail6 = ({ backFunction }) => (
  <React.Fragment>
    <Paragraph className="back" onClick={backFunction}>
      <FontAwesomeIcon icon={faAngleLeft} />
      See List
    </Paragraph>
    <Heading>Planning and Development Directorate</Heading>
    <Paragraph>
      As part of the Planning and Development Services Directorate, Planning
      Directorate's function is to assist PDA, the development community and the
      public in matters relating to land use planning. In providing such
      assistance, our primary goal is to manage growth in Peshawar and to ensure
      a high quality of development having regard for the health, safety and
      welfare of the existing community. The Directorate is responsible for
      maintaining and updating Peshawar's Official Plan and Zoning By-law as
      well as the processing and administration of various planning applications
      in a timely and efficient manner. The Planning Directorate also responds
      to general inquiries related to land use planning matters and prepares
      statistical information such as population counts and projections as well
      as inventories with respect to residential, commercial and industrial
      land.
    </Paragraph>
  </React.Fragment>
);
var Detail7 = ({ backFunction }) => (
  <React.Fragment>
    <Paragraph className="back" onClick={backFunction}>
      <FontAwesomeIcon icon={faAngleLeft} />
      See List
    </Paragraph>
    <Heading>Building Control Directorate</Heading>
    <Paragraph className="my-3">
      <b>By-law Enforcement Directorate</b>
    </Paragraph>
    <Paragraph>
      As part of the Planning and Development Services Directorate, the By-law
      Enforcement Directorate's function is to ensure public health and safety
      through the enforcement of several Authority By-laws. By-laws including
      Zoning, Property Standards, Discharge of Firearms, Fencing, Burning, Waste
      Management, Parking, Fireworks and Noise are enforcement by this
      Directorate.
    </Paragraph>
  </React.Fragment>
);
var Detail8 = ({ backFunction }) => (
  <React.Fragment>
    <Paragraph className="back" onClick={backFunction}>
      <FontAwesomeIcon icon={faAngleLeft} />
      See List
    </Paragraph>
    <Heading>Parks and Horticulture Directorate</Heading>
    <Paragraph>
      Peshawar is known for its magnificent tree lined Avenues and beautiful
      gardens which very few cities in the work can match. These trees are our
      great natural heritage and have been witness to the great freedom struggle
      and growth of Pakistan Nation since Independence . Peshawar also takes
      pride in its wealth of more than 200 species of local and ornamental trees
      in its gardens adding color to life in various seasons with their foliage
      and flowers. The Horticulture Department of PDA which is responsible for
      maintenance of this urban oasis strives to make Peshawar greener, more
      beautiful and provide healthy pollution free environment to the
      distinguished residents and visitors. Horticulture Department is one of
      the major departments of PDA which looks after public greens including
      parks, gardens, roundabouts, roadside green strips and Avenue Trees that
      makes Peshawar what it is. Horticulture Department is headed by Director.
    </Paragraph>
    <Paragraph className="my-3">
      <b>Functions of the Wing:</b>
    </Paragraph>
    <Paragraph className="mt-4">
      <ul>
        <li>
          The laying out or the maintenance of public parks, gardens or
          recreation grounds.
        </li>
        <li>The planting and care of trees on roadsides and elsewhere.</li>
        <li>
          The provision of unfiltered water supply ( Presently PDA receives from
          W&SD for certain Parks and Gardens. )
        </li>
        <li>
          The department also maintains green strips of aprx. 100 roads and
          lanes of Peshawar including Avenue Trees.
        </li>
      </ul>
    </Paragraph>
    <Paragraph className="my-3">
      <b> The Department carries out following activities:</b>
    </Paragraph>
    <Paragraph className="mt-4">
      <ul>
        <li>
          Creation & Maintenance of Parks & Gardens including Roundabouts and
          roadside greens.
        </li>
        <li>Maintenance of colony parks.</li>
        <li>
          Tree Management: Tree plantation, Pruning of trees, Removal of fallen,
          dry and dangerous trees.
        </li>
        <li>
          Removal of horticulture wastes (from Parks & Gardens & Road belts to
          designated point of Sanitation Deptt.) and Composting.
        </li>
        <li>Plantation / Landscaping on the available land.</li>
        <li>
          Floral Decoration of routes of national/international events in PDA
          area, participation in Flower shows, PDA functions etc.
        </li>
        <li>Attending public complaints on horticulture issues.</li>
        <li>Maintenance and improvement of PDA Colony parks.</li>
        <li>
          Creation & Maintenance of Nurseries of saplings of trees, shrubs &
          seedbeds of ground covers & seasonal flowers.
        </li>
        <li>
          Creating public awareness for enlisting public support in conservation
          and plantation efforts
        </li>
      </ul>
    </Paragraph>
  </React.Fragment>
);
var Detail9 = ({ backFunction }) => (
  <React.Fragment>
    <Paragraph className="back" onClick={backFunction}>
      <FontAwesomeIcon icon={faAngleLeft} />
      See List
    </Paragraph>
    <Heading>Legal Wing</Heading>
    <Paragraph className="my-3">
      <b>Functions of the Wing:</b>
    </Paragraph>
    <Paragraph className="mt-4">
      <ul>
        <li>
          To defend litigation for and against the PDA from Civil Court to
          Supreme Court including Labor Courts, Labor Appellate Tribunal,
          N.I.R.C. Wages Authority ,Ombudsman Punjab , Other competent
          Authorities.
        </li>
        <li>To arrange Comments, documents from concerned Directorates.</li>
        <li>
          To arrange certified copies of Court's orders and judgments passed
          against the Authority for filling appeals in the next higher courts.
        </li>
        <li>
          To arrange filling of appeals against the judgments / decrees
          affecting the interest of the Authority before the competent Court
          within the Limitation period.
        </li>
        <li>To ensure timely production of evidence in the Courts.</li>
        <li>
          To exercise monitoring of working of all Legal advisors / Standing
          Counsel of Authority in different Courts.
        </li>
        <li>
          To ensure timely communication of vacation of stay orders / disposal
          of cases to concerned Directorates for prompt action.
        </li>
        <li>
          To recommend appointments / extension of services of Legal advisors /
          Standing Counsel to the competent Authority.
        </li>
        <li>
          To monitor the working of subordinate staff of Legal Directorate.
        </li>
        <li>
          To clarify and interpret courts orders for the guidance of concerned
          Directorates.
        </li>
        <li>To tender advice to concerned Directorates on legal issues.</li>
        <li>
          To arrange timely filling of replies / written statements in various
          courts on the basis of comments supplied by concerned Directorates.
        </li>
        <li>
          To ensure satisfaction of courts decrees having attained finality by
          the concerned Directorates.
        </li>
        <li>
          To arrange vacation of Stay Orders hampering vital projects of
          Authority.
        </li>
      </ul>
    </Paragraph>
  </React.Fragment>
);
export default AboutDirectorates;
