import React, { useEffect, useRef, useState } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Navbar from '../../Navbar/Navbar'
import Events from '../Events/Events'
import Footer from '../Footer/Footer'
import MySlider from '../MySlider/MySlider'
import News from '../News/News'
import QuickNav from '../QuickNav/QuickNav';
import Home from "../../Pages/Home/Home"
import About from "../../Pages/About/About"
import Tenders from "../../Pages/Tenders/Tenders"
import "./pagesWrapper.css"
import Advertisments from '../../Pages/Advertisments/Advertisments'
import Projects from '../../Pages/Projects/Projects'
import ContactUs from '../../Pages/ContactUs/ContactUs';
import Loader from "../Loader/Loader"
import NotFound from '../../Pages/NotFound/NotFound'
import SingleNews from '../../Pages/SingleNews/SingleNews'
import NewsPage from '../../Pages/NewsPage/NewsPage'
const PagesWrapper = ({history:{location:{pathname}}}) => {


    const [load,setLoad] = useState(true)
    const pageRef = useRef(0)
    useEffect(()=>{
      
        setLoad(true);
        pageRef.current.scrollIntoView()

    },[pathname])
    return (
        <div className="pages-wrapper position-relative">
            <Navbar/>
            <MySlider/>
            <div className="mainer row mx-0">
                <div className="col-md-3 px-0 "  >
                    <News />
                    <Events/>
                    <QuickNav/>
                </div>
                <div className="col-md-9 px-0 d-flex align-items-end pages h-100">
                    <div className="page" ref={pageRef}>
                        <Switch>
                            <Route path="/" component={()=> <Home setLoad={setLoad}/>} exact/>
                            <Route path="/about" component={()=><About setLoad={setLoad}/>}/>
                            <Route path="/advertisements/:pageNumber" component={()=><Advertisments setLoad={setLoad}/>}/>
                            <Route path="/projects/:type/:pageNumber" component={()=><Projects setLoad={setLoad}/>}/>
                            <Route path="/contact" component={()=> <ContactUs setLoad={setLoad}/>}/>
                            <Route path="/single-news/:newsId" component={(props)=> <SingleNews {...props} screenLoader={setLoad}/>}/>
                            <Route path="/news/:pageNumber" component={(props)=> <NewsPage {...props} screenLoader={setLoad}/>}/>
                            
                            <Route path="/tenders/:pageNumber" component={()=> <Tenders screenLoader={setLoad}/>}/>
                            <Route component={()=> <NotFound setLoad={setLoad}/>}/>
                        </Switch>

                    </div>
                   
                </div>
            </div>
            <Footer/>
            {
                        load ? 
                <Loader/>
                                :""
                    }
        </div>
    )
}

export default withRouter(PagesWrapper)
