import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import {  Route, Switch, useHistory, withRouter } from 'react-router-dom';
import AboutContent from '../../Components/AboutContent/AboutContent';

import Heading from "../../Components/Heading/Heading";
import NotFound from '../NotFound/NotFound';
import "./About.css"
import AboutDirectorates from './AboutDirectorates';
import AboutPeshawar from './AboutPeshawar';
import Downloads from './Downloads';
import EventsPage from './EventsPage';
import Gallery from './Gallery';
import PictureGallery from './PictureGallery';
const About = (props) => {
    var history = useHistory();
    const [pageName,setPageName] = useState("")
    var linkSwitcher = (url,name)=>{
            history.push(url);
            setPageName(name)
    }
    var {location:{pathname},setLoad} = props;
    
    var selectLinkOnLoad = ()=>{
       
    switch (pathname) {
    
      case  "/about":
        setPageName("About KDA");
        break;
    case "/about/about-peshawar":
        setPageName("About Peshawar")
        break;
       case "/about/events" :
           setPageName("Events")
           break;
          case "/about/directorates":
              setPageName("Directorates of KDA Development Authority")
        break; 
        case "/about/picture_gallery" :
            setPageName("Events Gallery")
            break;
          case "/about/gallery":
            setPageName("Events Gallery")
            break;
           case "/about/downloads/":
               setPageName("Downloads") 
               break;
      default:
        setPageName("") 
        break;
    }      
  }
  useEffect(()=>{
    selectLinkOnLoad();
      },[pathname])
  
    return (
        <div className="about-page">
           <div className="p-5">
           {
               pageName ? <Heading fontSize={30} className="hhh">
               {pageName}
                </Heading>:""
           }
           <div className="row m-0 link-checks">
               <div className="col-md-3">
            
               <Heading className="">
                      About KDA
                    </Heading>
                   <Form.Check  onClick={()=> linkSwitcher("/about","About KDA")} defaultChecked={pathname === "/about"}  name="myLink" type="radio" />
            
               </div>
               <div className="col-md-3">
               <Heading className="">
                      About Peshawar
                    </Heading>
                   <Form.Check onClick={()=> linkSwitcher("/about/about-peshawar"," About Peshawar")} defaultChecked={pathname === "/about/about-peshawar"} name="myLink" type="radio"/>
               </div>
               <div className="col-md-3">
               <Heading className="ml-5">
                     Events
                    </Heading>
                   <Form.Check onClick={()=> linkSwitcher("/about/events","Events")} name="myLink" defaultChecked={pathname === "/about/events"} type="radio"/>
               </div>
               <div className="col-md-3">
               <Heading className="">
                    Directorates
                    </Heading>
                   <Form.Check onClick={()=> linkSwitcher("/about/directorates","Directorates of KDA Development Authority")} defaultChecked={pathname === "/about/directorates"} name="myLink" type="radio"/>
               </div>
               <div className="col-md-3">
               <Heading className="">
                    Picture Gallery
                    </Heading>
                   <Form.Check onClick={()=> linkSwitcher("/about/picture_gallery","Events Gallery")}  defaultChecked={pathname === "/about/picture_gallery"}  name="myLink" type="radio"/>
               </div>
               {/* <div className="col-md-4">
               <Heading className="">
                    Administration Heads
                    </Heading>
                   <Form.Check name="myLink" type="radio"/>
               </div> */}
               <div className="col-md-3">
               <Heading className="">
                    Downloads
                    </Heading>
                   <Form.Check onClick={()=> linkSwitcher("/about/downloads/1","Downloads")}  defaultChecked={pathname.includes(`/about/downloads`)}  name="myLink" type="radio" name="myLink" type="radio"/>
               </div>
           </div>
           <div className="mt-5">
               <Switch>
                   <Route path="/about" component={()=><AboutContent setLoad={setLoad}/>} exact/>
                   <Route path="/about/about-peshawar" component={()=><AboutPeshawar setLoad={setLoad}/>} />
                   <Route path="/about/events" component={()=> <EventsPage setLoad={setLoad}/>}/>
                   <Route path="/about/directorates" component={()=> <AboutDirectorates setLoad={setLoad}/>}/>
                   <Route path="/about/downloads/:pageNumber" component={()=> <Downloads setLoad={setLoad}/>}/>
                   <Route path="/about/picture_gallery" component={()=> <PictureGallery setLoad={setLoad}/>}/>
                   <Route path="/about/gallery/:id" component={()=><Gallery setLoad={setLoad}/>}/>
                   <Route component={()=> <NotFound setLoad={setLoad}/>}/>
                   
               </Switch>
           </div>
           </div>
        </div>
    )
}

export default withRouter(About)
