import React, { useState } from 'react'

import Heading from '../../Components/Heading/Heading'
import Loader from '../../Components/Loader/Loader'
import SearchTenderForm from '../../Components/SearchTenderForm/SearchTenderForm'
import TendersTable from '../../Components/TendersTable/TendersTable'


const Tenders = ({screenLoader}) => {
  const [searchValues,setSearchValues]  = useState({
    datefilter:"",
    date:"",
    diaryNo:"",
    departId:""
  })
  const [load,setLoad] = useState(true)

  React.useEffect(()=>{
     
     var t = ()=> setTimeout(()=>screenLoader(false),500) 
     t()
     return ()=>{
       clearTimeout(t)
     }
  },[])
    return (
        <div className="tender-page">
            <div className="p-5">
            <Heading fontSize={30} className="hhh mb-5">
               KDA Tenders
                </Heading>
                <SearchTenderForm setLoad={setLoad} setSearchValues={setSearchValues}/>
            </div>
                <TendersTable searchValues={searchValues} setLoad={setLoad}/>
                {
                        load ? 
                <Loader/>
                                :""
                    }
        </div>
    )
}

export default Tenders
