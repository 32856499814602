import React from 'react'
import "./newsListItem.css"
import img from "../../assets/img/news-1.png"
import Paragraph from '../Paragraph/Paragraph'
import { Link } from 'react-router-dom'
const NewsListItem = ({newsObj}) => {
    return (
        <div className="news-list-item">
            <img src={newsObj.Img_Url} alt="Image not loaded"/>
         <Link to={`/single-news/${newsObj.id}`}>
         <Paragraph className="news-link"
             style={{color:'var(--color-primary)'}}>
              {newsObj.Title}
            </Paragraph>
         </Link>
        </div>
    )
}

export default NewsListItem
