import React from 'react'
import Heading from "../../Components/Heading/Heading"

import PictureCollectionList from '../../Components/PictureCollectionList/PictureCollectionList'
const EventsPage = ({setLoad}) => {
    return (
        <div className="events-page">
           
         <PictureCollectionList setLoad={setLoad}/>
        </div>
    )
}

export default EventsPage
