import React from 'react'
import PictureCollectionList from '../../Components/PictureCollectionList/PictureCollectionList'
import "./PictureGallery.css"
const PictureGallery = ({setLoad}) => {
    return (
        <div className="picture-gallery">
            <PictureCollectionList setLoad={setLoad}/>
        </div>
    )
}

export default PictureGallery
