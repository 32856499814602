import React from 'react'
import { connect } from 'react-redux'
import { getCatName } from '../../Utility'

const CatName = ({catId,catagories}) => {
    const [catName,setCatName] = React.useState("")
    React.useEffect(()=>{
      
        var temp = getCatName(catId,catagories);
     
        setCatName(temp)
    },[catId,catagories])
    return (
        <>
         {catName}   
        </>
    )
}
var mapState = (state)=>({
    catagories: state.catagories
})
export default connect(mapState)(CatName)
