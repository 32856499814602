
import React from 'react'
import { Link } from 'react-router-dom'
import Heading from '../Heading/Heading'
import Paragraph from '../Paragraph/Paragraph'
import Button from '../Button/Button'
const PictureCollectionListItem = ({event}) => {
    return (
        <div className="pictureCollectionList_Item col-md-4">
            <div className="image-box ">
        <img src="https://www.pda.gkp.pk/images/gallery.ico" alt=""/>
        <div className="btn">
    <Link to={`/about/gallery/${event.id}`}>
    <Button>
                   View All
                    </Button>
    </Link>
        </div>
            </div>
         <div className="text-center name-sec">
        <Link  to={`/about/gallery/${event.id}`}>
        <Heading>
            {event.Title}
            </Heading>
        </Link>
        <Paragraph>
       {
           event.Description
       }

        </Paragraph>
         </div>
        </div>
    )
}

export default PictureCollectionListItem
