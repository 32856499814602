import React from 'react'
import "./styledButton.css"
const StyledButton = (props) => {
    var {style,className,...restProps} = props
    return (
        <button className={`styled-btn ${className}`} style={{...style}} {...restProps}>
            {props.children}
        </button>
    )
}

export default StyledButton
