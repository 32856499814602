export const SET_CATS = "SET_CATS"
export var catReducer = (state = [],actions)=>{
    var {type,payload} = actions;
    switch (type) {
        case SET_CATS:
            
            return payload.cats
    
        default:
        return state
    }
}