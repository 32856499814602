import React, { useEffect, useState } from 'react'
import { Pagination, Table } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import StyledButton from '../../Components/StyledButton/StyledButton'
import { fetchDownloads } from '../../Redux/Actions/Actions';
import { pagesCalculator } from '../../Utility';

const Downloads = ({setLoad,downloadsCount,match:{params:{pageNumber}}}) => {
  const [tableItems,setTableItems] = useState([])
  const [pages,setPages] = useState(1)
  useEffect( ()=>{

    var numberOfPages = pagesCalculator(downloadsCount) ? pagesCalculator(downloadsCount) : 1
    
      var temp =[]
      for (let number = 1; number <= numberOfPages; number++) {
      
        temp.push(
          <Pagination.Item key={number} active={number === Number(pageNumber)}>
              <Link to={`/about/downloads/${number}`}>
              {number}
              </Link>
          </Pagination.Item>,
        );
      }
      setPages(temp)
    
      
    },[pageNumber,downloadsCount])


    useEffect(async ()=>{
      var arr = await fetchDownloads(pageNumber)
      setTableItems(arr);
      setLoad(false)
    },[pageNumber])

    return (
        <div className="downloads">
               <Table  bordered >
        <thead>
          <tr>
            <th>S#</th>
            <th>Description</th>
            {/* <th style={{width:"8rem"}}> NIT No.</th> */}
            <th>Action</th>
         
          </tr>
        </thead>
        <tbody>
          {
            tableItems.length ? tableItems.map((d,i)=> <tr key={i}>
            <td>{++i}</td>
            <td>{d.Title}
</td>
         
            <td className="text-center">
                {
                  <a href={d.File_Url} target="_blank" download={`Download_${d.Title}`}>
                    
                <StyledButton style={{fontSize:"1.2rem",borderRadius:'1rem',width:"10rem",padding:"0",height:"3rem"}}>
                        Download
                </StyledButton>
                  </a>
                }
            </td>
          </tr>
        ):""
          }
       
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
             <Pagination className="mt-5 " size="lg">{pages}</Pagination>
                </div>
        </div>
    )
}
var mapState = (state)=>({
  downloadsCount:state.counts.downloadsCount
})
export default connect(mapState)(withRouter(Downloads))
