import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Paragraph from '../Paragraph/Paragraph'
const EventListItem = ({event}) => {
    const [image,setImage] = useState("");
    useEffect(()=>{
            var img = event.Image_Url.split('~');
            setImage(img[0])
    },[event])
    return (
        <div className="event-list-item">
               <img src={image} alt=""/>
         <Link to={`/about/gallery/${event.id}`}>
         <Paragraph className="news-link"
             style={{color:'var(--color-primary)'}}>
             {
                 event.Title
             }
            </Paragraph>
         </Link>
        </div>
    )
}

export default EventListItem
