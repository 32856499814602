
///counts

export const FETCH_COUNTS = "FETCH_COUNTS";
export const ADD_NUMBER = "ADD_NUMBER";
export const MINUS_NUMBER = "MINUS_NUMBER";
export const FETCH_PROJECTS_COUNTS = "FETCH_PROJECTS_COUNTS";
export const ADD_PROJECT_COUNT = "ADD_PROJECT_COUNT";
export const MINUS_PROJECT_COUNT = "MINUS_PROJECT_COUNT"
var initialState = {
    tendersCount:0,
    projectsCount:{
        c: 0,
    o: 0,
    f: 0,
    m: 0,
    h: 0
    },
    downloadsCount:0,
    eventsCount:0,
    adsCount:0
}

export var countReducer = (state=initialState,actions)=>{
    var {type,payload} = actions
    switch (type) {
     case FETCH_COUNTS:
         return {...state,...payload.counts}
  

  
            case FETCH_PROJECTS_COUNTS:
               
                return {
                    ...state,projectsCount:payload.projectsCount
                }
           
                
              

        default:
            return state;
    }
}