import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Heading from '../../Components/Heading/Heading'
import { API_TOKEN, _URL } from '../../Utility';
import Paragraph from '../../Components/Paragraph/Paragraph';
import "./SingleNews.css";

import Moment from 'react-moment';
const SingleNews = ({screenLoader,match:{params:{newsId}}}) => {
        const [singleNews,setSingleNews] = useState({})
        const history = useHistory();

        var fetchData = async ()=>{
            try {
                var query = await fetch(`${_URL}/api/news/${newsId}`,{
                   method:"get",
                   headers:{
                      Token: API_TOKEN
                   }
                })
                var data = await query.json();
               if(query.status === 200){
                 setSingleNews(data[0])
               }
             } catch (error) {
                console.log(error.message)
             }
        }

    useEffect(()=>{
            if(newsId){
                fetchData().then(res => screenLoader(false))
            }
            else{
                    history.push("/")
            }
    },[])
    var {Title = "",Description = "",Img_Url = "" , updated_at = ""} = singleNews
  
    return (
        <div className="p-5">
               <Heading fontSize={30} className="hhh">
                   News
                </Heading>
            <div className="row mt-5">
                <div className="col-md-12 text-center">
                   {
                       Img_Url ?  <img src={Img_Url} className="newsImg" alt=""/> : ""
                   }
                </div>
                <div className="col-md-12">
                    <Heading className="my-5 " fontSize={30}>
                        {Title}
                    </Heading>
                    <Heading style={{color:"var(--color-secendory)"}} className="mb-3">
                    <Moment format="D MMM YYYY" withTitle>{updated_at}</Moment>
                    </Heading>
                    <Paragraph>
                      {Description}
                    </Paragraph>
                </div>
            </div>

        </div>
    )
}

export default SingleNews
