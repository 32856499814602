import React, { useEffect, useState } from 'react'
import { API_TOKEN, convertObjectToArray, _URL } from '../../Utility'
import Heading from '../Heading/Heading'
import Loader from '../Loader/Loader'
import NewsListItem from '../NewsListItem/NewsListItem'
import "./News.css"
const News = () => {
    const [newsArr,setNewsArr] = useState([]);
    const [load,setLaod] = useState(true)
    var fetchData = async ()=>{
        try {
            var query = await fetch(`${_URL}/api/new/1`,{
               method:"get",
               headers:{
                  Token: API_TOKEN
               }
            })
            var data = await query.json();
           if(query.status === 200){
            if (!Array.isArray(data)) {
                data = convertObjectToArray(data);
              }
             setNewsArr(data)
           }
         } catch (error) {
            console.log(error.message)
         }
    }
useEffect(()=>{
fetchData().then(res => setLaod(false))
},[])

    return (
        <div className="news-container position-relative">
            <div className="side-heading">
                <Heading className="text-white">
                    News
                </Heading>
            </div>
            <div className="news-list">
            {
                load ? <Loader/> :      newsArr.map((n,i)=> <NewsListItem key={i} newsObj={n}/>)
             
            }
           
            </div>
        </div>
    )
}

export default News
