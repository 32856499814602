import {combineReducers} from 'redux';
import { catReducer } from './Catagories/catReducer';
import { countReducer } from './Counts/countsReducer';
import departReducer from './departments/departReducer';


var rootReducer = combineReducers({
        counts : countReducer,
        catagories : catReducer,
        departments : departReducer
})

export default rootReducer