
import { FETCH_COUNTS, FETCH_PROJECTS_COUNTS } from "./countsReducer";

export var setCounts = (counts)=>({
    type:FETCH_COUNTS,
    payload:{
        counts:{
            tendersCount:counts.Tender,
           
            downloadsCount:counts.Download,
            eventsCount:counts.Event,
            adsCount:counts.Advertisement,
            newsCount: counts.News
        }
    }
})

export var setProjectsCounts = (data)=>({
    type: FETCH_PROJECTS_COUNTS,
    payload:{
        projectsCount:{
         ...data
        }
    }
})