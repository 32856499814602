import React, { useEffect, useState } from 'react'
import { Pagination, Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import CatName from '../../Components/CatName/CatName'
import Heading from '../../Components/Heading/Heading'

import { fetchAdverts } from '../../Redux/Actions/Actions'
import { pagesCalculator, _URL } from '../../Utility'



const Advertisments = ({ads,match:{params:{pageNumber,}},setLoad}) => {
    const [tableItems,setTableItems] = useState([])
    const [pages,setPages] = useState(1)
    const [active,setActive] = useState(Number(pageNumber))
  var  mounted = false
useEffect( ()=>{

var numberOfPages = pagesCalculator(ads) ? pagesCalculator(ads) : 1

  var temp =[]
  for (let number = 1; number <= numberOfPages; number++) {
  
    temp.push(
      <Pagination.Item key={number} active={number === Number(pageNumber)}>
          <Link to={`/advertisements/${number}`}>
          {number}
          </Link>
      </Pagination.Item>,
    );
  }
  setPages(temp)

  
},[pageNumber,ads])
useEffect(()=>{
  mounted = true
return ()=>{
  mounted = false
}
},[])
useEffect(async ()=>{

  wrapperFunction()

 
},[pageNumber])

var wrapperFunction = async ()=>{
  var arr = await fetchAdverts(pageNumber)
  
  if(mounted){
    setTableItems(arr);
  }
  setLoad(false)
}
    return (
        <div>
            <div className="p-5">
            <Heading fontSize={30} className="hhh">
                    Advertisments
                </Heading>
           
            </div>
            <Table  bordered >
        <thead>
          <tr>
            <th>S#</th>
            <th>Title</th>
            <th >Category</th>
            <th>Posting Date</th>
            <th>End Date</th>
           
          </tr>
        </thead>
        <tbody>
        {
          tableItems ? tableItems.map((ad,i)=>   <tr key={i}>
          <td>{++i}</td>
          <td>{ad.Title}</td>
          <td><CatName catId={ad.Category_id}/></td>
          <td>{ad.Posting_Date}</td>
          <td>{ad.End_Date}  </td>
        
        </tr>
    ): ""
        }
      
        
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
             <Pagination className="mt-5 " size="lg">{pages}</Pagination>
                </div>
        </div>
    )
}
var mapState=(state)=>({
  ads : state.counts.adsCount
})

export default connect(mapState)(withRouter(Advertisments))
