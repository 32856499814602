import React  from 'react'
import { Link, NavLink } from 'react-router-dom'
import Paragraph from '../Components/Paragraph/Paragraph'
import "./Navbar.css";
import {getSiblings} from "../Utility"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
const Navbar = () => {
 
    var linkSelector = (e)=>{
        var item = e.target;
        var siblings = getSiblings(item);

        item.classList.add("active");
        siblings.forEach(sib => sib.classList.remove("active"))
    }
    var activeParentLink = (e)=>{
        var item = e.target.parentElement.parentElement;
        var siblings = getSiblings(item);
        siblings.forEach(sib => sib.classList.remove("active"))
        item.classList.add("active")
    }
    

    return (
        <div className="navbar-container">
            <NavLink to="/" className="navlink" activeClassName="active" exact onClick={linkSelector}>
             
              <Paragraph>
                    HOME
                </Paragraph>
        
            </NavLink>
            <NavLink to="/about" className="navlink " activeClassName="active" onClick={linkSelector} exact>
         
                <Paragraph>
                    ABOUT
                    <FontAwesomeIcon  icon={faAngleDown}/>  
                </Paragraph>
            <div className="nav-dropdown">
                <Link to="/about" onClick={activeParentLink}>
                  <Paragraph>
                  About KDA
                  </Paragraph>
                </Link>
                <Link to="/about/about-peshawar" onClick={activeParentLink}>
                  <Paragraph>
                  About Peshawar
                  </Paragraph>
                </Link>
                <Link to="/about/events" onClick={activeParentLink}>
                  <Paragraph>
                 Events
                  </Paragraph>
                </Link>
                <Link to="/news/1" onClick={activeParentLink}>
                  <Paragraph>
                 News
                  </Paragraph>
                </Link>
                <Link to="/about/directorates" onClick={activeParentLink}>
                  <Paragraph>
                 Directorates
                  </Paragraph>
                </Link>
                <Link to="/about/picture_gallery" onClick={activeParentLink}>
                  <Paragraph>
                 Picture Gallery
                  </Paragraph>
                </Link>
              
                <Link to="/about/downloads/1" >
                  <Paragraph>
                 Downloads
                  </Paragraph>
                </Link>
            </div>

                
            </NavLink>
            {/* <NavLink to="/services" activeClassName="active" className="navlink " onClick={linkSelector}>
         
         <Paragraph>
             Services
             <FontAwesomeIcon  icon={faAngleDown}/>  
         </Paragraph>
     <div className="nav-dropdown">
         <Link to="/about" onClick={activeParentLink}>
           <Paragraph>
           Street Lights
           </Paragraph>
         </Link>
         <Link to="/about" onClick={activeParentLink}>
           <Paragraph>
           Horticulture
           </Paragraph>
         </Link>
     </div>

         
     </NavLink> */}
            <NavLink to="/advertisements/1" activeClassName="active" className="navlink" onClick={linkSelector}>
            
                <Paragraph>
                advertisements
                </Paragraph>
          
            
            </NavLink>
            <NavLink to="/about/downloads/1" activeClassName="active" className="navlink" onClick={linkSelector}>
            
            <Paragraph>
                Downloads
            </Paragraph>
      
        
        </NavLink>
        <NavLink to="/projects/all/1" activeClassName="active" className="navlink " onClick={linkSelector}>
         
         <Paragraph>
             projects
             <FontAwesomeIcon  icon={faAngleDown}/>  
         </Paragraph>
     <div className="nav-dropdown">
         <Link to="/projects/completed/1" onClick={activeParentLink}>
           <Paragraph>
         Completed Projects
           </Paragraph>
         </Link>
         <Link to="/projects/ongoing/1" onClick={activeParentLink}>
           <Paragraph>
         Ongoing Projects
           </Paragraph>
         </Link>
         <Link to="/projects/future/1" onClick={activeParentLink}>
           <Paragraph>
        Future Planned Projects
           </Paragraph>
         </Link>
         <Link to="/projects/mega/1" onClick={activeParentLink}>
           <Paragraph>
     Mega Projects
           </Paragraph>
         </Link>
         <Link to="/projects/hschemes/1" onClick={activeParentLink}>
           <Paragraph>
         Housing Schemes
           </Paragraph>
         </Link>
         
     </div>

         
     </NavLink>
     <NavLink to="/tenders/1" className="navlink " activeClassName="active" onClick={linkSelector}>
         
         <Paragraph>
             Tenders
            
         </Paragraph>
  
     </NavLink>
        <NavLink to="/contact" className="navlink"  activeClassName="active"onClick={linkSelector}>
            
            <Paragraph>
                contact us
            </Paragraph>
      
        
        </NavLink>
      
        </div>
    )
}

export default Navbar
