import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import React, { useEffect, useRef, useState } from 'react'
import Button from '../Button/Button'
import Paragraph from "../Paragraph/Paragraph";
import logo from "../../assets/img/brand-logo.png"
import {Form} from "react-bootstrap"
import "./Header.css";
import { Link } from 'react-router-dom';
import Heading from "../Heading/Heading"
import { keyWords } from '../../Utility';


const Header = () => {
    return (
      <div className="col-md-12 my-3">
          <div className="upper-header ">
      <div className="d-flex justify-content-center w-100">
      <Paragraph className="mr-5">
      <FontAwesomeIcon icon={faEnvelope}/>      Email : info@Igkp.gov.pk
              </Paragraph>
              <Paragraph>
                 <FontAwesomeIcon icon={faEnvelope}/> Hotline : (021) 36521256
              </Paragraph>
      </div>
          
          </div>
          <div className="lower-header row  mt-4">
              <div className="col-md-12 text-center mx-0 logo-class">
                <img src={logo} alt="" width="50%"/>
              </div>
            
          </div>
      </div>
    )
}
{/* <div className="col-md-6 mx-0">
<SearchBar/>
</div> */}

const SearchBar = () => {

    var dropDown = useRef(0)
    const [value,setValue] = useState("");
    const [searchLinks,setSearchLink] = useState([])
    var searchFunction = ()=>{
            var temp = keyWords.filter(el => el.key[0].toLowerCase().includes(value.toLowerCase()))
            setSearchLink(temp)
    }
    useEffect(()=>{
            if(!value){
              setSearchLink([])

            }
            else{
                searchFunction()
            }
    },[value])
    var closeDropdown = (e)=>{
           setSearchLink([]);
           setValue("")
    }
    useEffect(() => {
        window.addEventListener("click",closeDropdown)
    }, []);
    return (
        <div className="search-input d-flex shadow  px-5 position-relative">
        <Paragraph style={{color:"var(--color-primary)"}}>
            Search by keywords 
        </Paragraph>
        <Form.Control type="text" value={value} onChange={(e)=> setValue(e.target.value)} />
        <Link to={`/${value}`}>
        <Button>
            GO
        </Button>
        </Link>
            {
                searchLinks.length ?    <div className="searchdropdown" ref={dropDown}>
                {
                    searchLinks.length ? searchLinks.map((el,i) =>       <Link key={i} to={el.link}>
                        <Heading className="d-link">
                                {
                                    el.key
                                }
                        </Heading>
                    </Link>):""
                }
                    
            </div>:""
            }
      </div>
    )
}



export default Header
