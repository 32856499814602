import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading'
import NewsListItem from '../../Components/NewsListItem/NewsListItem';
import { API_TOKEN, convertObjectToArray, pagesCalculator, _URL } from '../../Utility';

const NewsPage = ({screenLoader,match:{params:{pageNumber}},count}) => {
    const [newsArr,setNewsArr] = useState([]);
    const [pages,setPages] = useState(1)

    useEffect( ()=>{

        var numberOfPages = pagesCalculator(count) ? pagesCalculator(count) : 1
        
          var temp =[]
          for (let number = 1; number <= numberOfPages; number++) {
          
            temp.push(
              <Pagination.Item key={number} active={number === Number(pageNumber)}>
                  <Link to={`/news/${number}`}>
                  {number}
                  </Link>
              </Pagination.Item>,
            );
          }
          setPages(temp)
        
          
        },[pageNumber,newsArr])
    var fetchData = async ()=>{
        try {
            var query = await fetch(`${_URL}/api/new/${pageNumber}`,{
               method:"get",
               headers:{
                  Token: API_TOKEN
               }
            })
            var data = await query.json();
           if(query.status === 200){
            if (!Array.isArray(data)) {
                data = convertObjectToArray(data);
              }
             setNewsArr(data)
           }
         } catch (error) {
            console.log(error.message)
         }
    }
    
useEffect(()=>{
fetchData().then(res => screenLoader(false))
},[])
    return (
        <div className="p-5">
              <Heading fontSize={30} className="hhh">
                   News
                </Heading>
               <div className="container">
               <div className=" row mt-5">
               
            {
               newsArr.map((n,i)=> <NewsListItem key={i} newsObj={n}/>)
             
            }
           {
               newsArr.length ?   <div className="d-flex justify-content-center w-100">
               <Pagination className="mt-5 " size="lg">{pages}</Pagination>
                  </div>:""
           }
            
                </div>
               </div>
        </div>
    )
}
var mapState=(state)=>({
    count : state.counts.newsCount
  })
  
export default connect(mapState)(NewsPage)
