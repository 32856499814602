import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { submitContactForm } from '../../Redux/Actions/Actions'
import { getApiMessage, ValidateEmail } from '../../Utility'
import Heading from '../Heading/Heading'
import Loader from '../Loader/Loader'
import Paragraph from '../Paragraph/Paragraph'
import StyledButton from '../StyledButton/StyledButton'
import "./getinTouch.css"
const GetInTouchForm = () => {
    
    const [formValues,setFormValues] = useState({
        name:"",
        email:"",
        msg:""
    })
    const [msg,setMsg]  = useState({});
    const [isInvalid,setInvalid] = useState(false)
    const [load,setLoad] = useState(false)
var handleSubmit = async (e)=>{
e.preventDefault();
var {email,name,msg} = formValues;
var check = ValidateEmail(email);
if(check){
    if(name.length >= 3 && msg.length >= 3){
        setLoad(true)
        var obj = {
            Name:name,
            Email:email,
            Message:msg
        }
        var status = await submitContactForm(obj);
        var message = getApiMessage(status);
        setMsg(message)
    }
    else{
        setMsg({error:"Values should be atleast 3 letter long"})
    }
}
else{
    setMsg({error:"Enter Valid Email"})
}
setLoad(false)
}
    useEffect(()=>{
            if(formValues.email){
                var check = ValidateEmail(formValues.email);
            if(check){
                setInvalid(false)
            }
            else{
                setInvalid(true)
            }
            }
            else{
                setInvalid(false)

            }
    },[formValues.email])
        
    return (
        <div className="getintochForm p-5 position-relative">
              <Heading fontSize={30} className="hhh mb-5">
               Get In Touch
                </Heading>
                <Paragraph>
                    <b>
                        Monday-Friday:
                    </b>
                    10am to 8pm ,
                    <b>
                        Saturday:
                    </b>
                    11am to 3pm ,
                    <b>
                        Sunday:
                    </b>
                    Closed
                </Paragraph>
                <Form className="row mt-3" onSubmit={handleSubmit}>
                  <div className="col-md-6 d-flex align-items-center">
                  <Paragraph style={{color:"var(--color-primary)"}} className="mr-4">
                       Name: 
                    </Paragraph>
                    <Form.Control value={formValues.name} onChange={(e)=> setFormValues((prevValues) => ({...prevValues , name:e.target.value}))} type="text"  required={true}/>
                   
                  </div>
                  <div className="col-md-6 d-flex align-items-center">
                  <Paragraph style={{color:"var(--color-primary)"}} className="mr-4" >
                       Email: 
                    </Paragraph>
                    <Form.Control value={formValues.email} onChange={(e)=> setFormValues((prevValues) => ({...prevValues , email:e.target.value}))} type="email" isInvalid={isInvalid} required={true} />
                   
                  </div>
                  <div className="col-md-12 mt-4">
                  <Form.Control value={formValues.msg} onChange={(e)=> setFormValues((prevValues) => ({...prevValues , msg:e.target.value}))} as="textarea" rows={3} placeholder="Type your question here..." required={true}/>
                  </div>
                  <div className="col-md-12 mt-2">
                      {
                          msg.error ? <Paragraph className="text-danger">
                                    {
                                        msg.error
                                    }
                          </Paragraph>:
                          <Paragraph className="text-success">
                                {
                                msg.success
                                }
                          </Paragraph>
                      }
                  </div>
                 <div className="col-md-12">
                 <StyledButton  className="styled-btn-green mt-5 float-right">
                    SUBMIT
                </StyledButton>
                 </div>
                 
                </Form>
                {
                    load ? <Loader/> : ""
                }
        </div>
    )
}

export default GetInTouchForm
