import React from 'react'
import { Spinner } from 'react-bootstrap'
import "./Loader.css"
const Loader = () => {
    return (
        <div className="loader">
            <Spinner animation="border" className="sp" size="lg"/>
        </div>
    )
}

export default Loader
