import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import Heading from '../../Components/Heading/Heading';
import { fetchSingleEvent } from '../../Redux/Actions/Actions';
import {createChunks} from "../../Utility"
const Gallery = ({setLoad,match:{params:{id}}}) => {
    const [items,setItems] = useState([]);
    const [show,setShow] = useState(false);
    const [image,setImage] = useState("");
    useEffect(async ()=>{
            var temp = await fetchSingleEvent(id);
              var desired = temp[0];
                var imgs = desired.Image_Url.split('~');
                var k = 3;
                
          var chunks = createChunks(imgs,5)
          setItems(chunks)
               setLoad(false)
    },[id])
  
        var handleShow = (img)=>{
          setImage(img);
          setShow(true)
        }
     
    return (
        <div className="gallery ">
          {
              items.map((chunck,i)=>  
               <div className={`part-${++i}`} key={i}>
                    {
                        chunck.map(img =>  <img src={img} className="gallery-image" key={img} alt="" onClick={()=> handleShow(img)}/>)
                    }
              </div>)
          }
          
        <ImagePreview image={image} show={show} onHide={()=> setShow(false)}/>    

        </div>
    )
}


const ImagePreview = (props)=>{
  var {image,...restProps} = props

  return (
    <Modal {...restProps} centered>
       <Modal.Header closeButton>
      <Modal.Title>
          <Heading fontSize={24} className="hhh">
             Image Preview
          </Heading>
      </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-img">
        <img src={image} alt=""/>
      </Modal.Body>
  </Modal>

  )
}








export default withRouter(Gallery)
