import { faEnvelope, faGlobe, faHome, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import GetInTouchForm from '../../Components/GetInTouchForm/GetInTouchForm'
import Heading from '../../Components/Heading/Heading'
import Paragraph from '../../Components/Paragraph/Paragraph'
import "./contact.css"
const ContactUs = ({setLoad}) => { 
    React.useEffect(()=>{
         var t = ()=> setTimeout(()=> setLoad(false),500)   
         t();
         return ()=>{
             clearTimeout(t)
         }
    },[])
    return (
        <div className="contact-page">
             <div className="p-5">
           <Heading fontSize={30} className="hhh">
            Contact Us
                </Heading>
                <div className="small-table mt-5">
                    <div className="row">
                        <div className="col-md-4">
                        <Heading>
                    Directorate Name	
                    </Heading>
                        </div>
                        <div className="col-md-4">
                        <Heading>
                Contact #	
                    </Heading>
                        </div>
                        <div className="col-md-4">
                        <Heading>
                Email Address
	
                    </Heading>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                        <Paragraph>
                    Director General Directorate	
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                    9217035-36

                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                            <Paragraph>
                            dgpdahst@gmail.com

                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                        <Paragraph>
                        Administration Directorate Secretary PDA	
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217071

                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        admin@pda.gkp.pk


                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Directorate Engineeering Road-III		
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217074

                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        revenue@pda.gkp.pk



                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Electrical	
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217031

                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        finance@pda.gkp.pk



                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Estate Management Directorate	
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217037	
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        estatemgmt@pda.gkp.pk

                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Directorate Engineering- RMT		
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217036
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        townplanning@pda.gkp.pk

                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Land Acquisition Collector Directorate		
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217242	
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        landdevelopment@pda.gkp.pk

                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Planning and Designing Directorate			
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217500	
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        planningdesigning@pda.gkp.pk


                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Directorate Buildings			
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217725		
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        watersanitation@pda.gkp.pk

                            </Paragraph>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Building Control Directorate				
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217167			
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        buildingcontrol@pda.gkp.pk


                            </Paragraph>
                        </div>                        
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Directorate Engineering Road-II				
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217034	
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        horticulture@pda.gkp.pk


                            </Paragraph>
                        </div>   
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Legal Wing				
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        2917130		
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        legal@pda.gkp.pk


                            </Paragraph>
                        </div>   
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Computer Section			
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        -		
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        computersection@pda.gkp.pk


                            </Paragraph>
                        </div>  
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Directorate Engineering Road-I				
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        9217033	
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        complaints@pda.gkp.pk



                            </Paragraph>
                        </div>  
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <Paragraph>
                        Central Exchange of PDA				
                    </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                      -
                            </Paragraph>
                        </div>
                        <div className="col-md-4">
                        <Paragraph>
                        info@pda.gkp.pk

                            </Paragraph>
                        </div>  
                    </div>
                 
           
</div>
            <div className="row mt-5">
                <div className="col-md-6 c-details" >
                    <Heading fontSize={20}>
                        Contact Us
                    </Heading>
                    <Paragraph>
                        <FontAwesomeIcon icon={faHome}/>
                        Phase 5 Kohat
                    </Paragraph>
                    <Paragraph>
                        <FontAwesomeIcon icon={faEnvelope}/>
                        dgpdahst@gmail.com
                    </Paragraph>
                    <Paragraph>
                        <FontAwesomeIcon icon={faPhone}/>
                        9217035-36 9217035-36
                    </Paragraph>
                    <Paragraph>
                        <FontAwesomeIcon icon={faGlobe}/>
                        http://www.pda.gkp.pk
                    </Paragraph>
                </div>
                <div className="col-md-6">
                    <div className="float-right c-details">
                    <Heading fontSize={20}>
                      Business Hours
                    </Heading>
                    <Paragraph>
                      <b>Monday-Friday</b>: 10am to 8pm
                     
                    </Paragraph>
                    <Paragraph>
                      <b>Saturday</b>: 11am to 3pm  
                    </Paragraph>
                  
                    <Paragraph>
                        <b>Sunday</b>: Closed
                       
                    </Paragraph>
                    </div>
                </div>
            </div>
                </div>  
           <GetInTouchForm/>
        </div>
    )
}

export default ContactUs
