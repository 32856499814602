
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Table,Modal, Pagination } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { fetchAllTenders } from '../../Redux/Actions/Actions';
import { pagesCalculator } from '../../Utility';
import DepartmentName from '../DepartmentName/DepartmentName';
import Heading from '../Heading/Heading';
import Paragraph from '../Paragraph/Paragraph';
import StyledButton from '../StyledButton/StyledButton'
import "./TendersTable.css"
const TendersTable = (props) => {
  var {match:{params:{type,pageNumber}},counts = {},setLoad,searchValues} = props
  const [allTenders,setAllTenders]  = useState([])
  const [tableItems,setTableItems] = useState([])
  const [pages,setPages] = useState(1)
  useEffect( ()=>{
   
    var numberOfPages = pagesCalculator(counts) ? pagesCalculator(counts) : 1
    
      var temp =[]
      for (let number = 1; number <= numberOfPages; number++) {
      
        temp.push(
          <Pagination.Item key={number} active={number === Number(pageNumber)}>
              <Link to={`/projects/${type}/${number}`}>
              {number}
              </Link>
          </Pagination.Item>,
        );
      }
      setPages(temp)
    
      
    },[pageNumber,counts])

      useEffect(()=>{
       setLoad(true);
       var {datefilter = "",date = "",diaryNo = "",departId} = searchValues;
       
          if(datefilter && date){
            var temp = allTenders.filter((el)=> {
              var tenderDate = new Date(el[datefilter])
              var filterDate = new Date(date);
                if(departId){

                  return  tenderDate.getTime() === filterDate.getTime() && el.Dairy_No.toLowerCase().includes(diaryNo.toLowerCase()) && el.Dept_id === departId
                }
                else{
                  return  tenderDate.getTime() === filterDate.getTime() && el.Dairy_No.toLowerCase().includes(diaryNo.toLowerCase()) 
                  
                }
          
          })
         
          setTableItems(temp)
          }
          else{
            setTableItems(allTenders)
          }
     setLoad(false)
      },[searchValues])

    useEffect(async ()=>{
      var arr = await fetchAllTenders(pageNumber);
      setAllTenders(arr)
      setTableItems(arr);
      setLoad(false)
    },[pageNumber])
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (t) => {
      setTenderToView(t)
      setShow(true)};
    const [TenderToView,setTenderToView] = useState({})

    return (
    <React.Fragment>
            <Table  bordered >
        <thead>
          <tr>
            <th>S#</th>
            <th>Title</th>
            <th style={{width:"8rem"}}> Diary No.</th>
            <th>Department</th>
            <th>Issue Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {
          tableItems.length ? tableItems.map((t,i)=>   <tr key={i}>
          <td>{++i}</td>
          <td>{t.Title}</td>
          <td>{t.Dairy_No}</td>
          <td><DepartmentName dptId={t.Dept_id}/></td>
          <td>{t.Issue_Date} </td>
          <td className="text-center">
              <StyledButton 
              onClick={()=> handleShow(t)}
              className="mb-3 mr-3" style={{fontSize:"1.2rem",borderRadius:'1rem',width:"10rem",padding:"0",height:"3rem"}} >
                      View
              </StyledButton>
              <a href={t.File_Url} download={`Tender-${t.Title}`}>
              <StyledButton style={{fontSize:"1.2rem",borderRadius:'1rem',width:"10rem",padding:"0",height:"3rem"}}>
                      Download
              </StyledButton>
              </a>
          </td>
        </tr>):""
        }
        
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
                <Pagination className="mt-5 " size="lg">{pages}</Pagination>
                </div>
          <TenderDetailModal show={show} onHide={handleClose} TenderToView={TenderToView}/>
        
    </React.Fragment>
    )
}


const TenderDetailModal = ({show,onHide,TenderToView})=>{
  var {id,Title,Dairy_No,Reference_No,Receiving_Date,Issue_Date,Description,Dept_id,Town_Name,Source,Opening_Date,Last_Date_Sub,File_Url} = TenderToView;
  return (
    <Modal show={show} className="tender-modal" onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>
          <Heading fontSize={24} className="hhh">
              Tender Details
          </Heading>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="tender-details row m-0">
             <div className="col-md-6">
                 <Paragraph>
                     Diary No.
                 </Paragraph>
                 
             </div>
             <div className="col-md-6">
                 <Paragraph>
                    {Dairy_No}
                 </Paragraph>
             </div>
             <div className="col-md-6">
               <Paragraph>
                 Reference No.
               </Paragraph>
             </div>
             <div className="col-md-6">
               <Paragraph>
                {Reference_No}
               </Paragraph>
             </div>
             <div className="col-md-6">
               <Paragraph>
                 Tender Receiving Date
               </Paragraph>
             </div>
             <div className="col-md-6">
               <Paragraph>
                {Receiving_Date}
               </Paragraph>
             </div>
             <div className="col-md-6">
               <Paragraph>
                 Tender Issue Date By Department
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                {Issue_Date}
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                Tender Description
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                {Description}
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                 Tender's Department Name
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                <DepartmentName dptId={Dept_id}/>
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
               Tender's Town Name
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                 {Town_Name}
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
              Tender's Source From
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
            {Source}
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
               Tender Opening Date
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
               {Opening_Date}
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                Tender's Last Date of Submission
               </Paragraph>
             </div>
             <div className="col-md-6">
             <Paragraph>
                {Last_Date_Sub}
               </Paragraph>
             </div>
             {/* <div className="col-md-6">
             <Paragraph>
                Previous Tender
               </Paragraph>
             </div>
             <div className="col-md-6">
            <Link>
            <Paragraph>
                 View
                 </Paragraph>
            </Link>
             </div> */}
        </div>
    </Modal.Body>
    <Modal.Footer className="justify-content-center " >
             <a href={File_Url} download={`Tender-${Title}`}>
             <StyledButton style={{borderRadius:"2rem",background:"transparent",padding:"1rem 8rem"}} >
              Download
          </StyledButton>
             </a>
    </Modal.Footer>
  </Modal>
  )
}
var mapState = (state) =>({
  counts : state.counts.tendersCount
})
export default connect(mapState)(withRouter(TendersTable))
