import React, { useRef, useState } from 'react'
import { Form, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import Heading from '../Heading/Heading'

import Paragraph from '../Paragraph/Paragraph'
import StyledButton from '../StyledButton/StyledButton'
import "./searchTenderForm.css"
const SearchTenderForm = ({setSearchValues,departments,setLoad}) => {
    const [formValues,setFormValues] = useState({});
    var selectNode = useRef(0)
    var handleFormValues = (e)=>{
        var {name,value} = e.target;
       
        setFormValues((prevValues)=>({
            ...prevValues,
            [name]: value
        }))
    }
    var selectDateFilter = (e)=>{
   
        setFormValues((prevValues)=>({
            ...prevValues,
            dateFilter:e.target.value
        }))
    }
    const [msg,setMsg] = useState({})
    var {date = "",Dairy_No = "",Dept_id = ""} = formValues;
    var handleSubmit = (e)=>{
        e.preventDefault();
       if(formValues.dateFilter){
           if(date){
            
            //  setLoad(true)
               setMsg({})
                setSearchValues((prevValues)=>({
                    ...prevValues,
                    datefilter:formValues.dateFilter,
                    date,
                    diaryNo:Dairy_No,
                    departId:formValues.Dept_id
                }))
           }
           else{
            setMsg({error:"Please select date"});
           }
       }
       else{
           setMsg({error:"Please select date type"})
       }
    }
    var departSelector = (e)=>{
        setFormValues((prevValues)=>({
            ...prevValues,
            Dept_id:e.target.value
        }))
    }

    var clearSearch = ()=>{
        setFormValues({})
        setSearchValues({  datefilter:"",
date:"",
diaryNo:"",
departId:""})
selectNode.current.value = ""
    }
    return (
        <Form className="search-tender-form" onSubmit={handleSubmit}>
            <Form.Group as={Row} >
                <div className="col-md-2 p-0">
                    <Heading className="xhsh">
                        Search By:
                    </Heading>
                </div>
                <div className="col-md-3">
                <Form.Check 
              type="radio"
              value="Issue_Date"
        name="myRadio"
        label="Issue Date By Dept:"
        onChange={selectDateFilter}
      />
                </div>
                <div className="col-md-3">
                <Form.Check 
              type="radio"
              value="Receiving_Date"
              name="myRadio"
        label="Receiving Date:"
        onChange={selectDateFilter}

      />
                </div>
                <div className="col-md-3">
                <Form.Check 
              type="radio"
              value="Opening_Date"
              name="myRadio"
        label="Opening Date:"
        onChange={selectDateFilter}

      />
                </div>
            </Form.Group>
            <Form.Group as={Row}> 
            <div className="col-md-2 p-0">
                    <Heading className="xhsh">
                       Enter Date:
                    </Heading>
                </div>
                <div className="col-md-4">
                    <Form.Control type="date" name="date" value={date} onChange={handleFormValues} placeholder="" required/>
                </div>
                <div className="col-md-2">
                <Heading className="xhsh ml-4">
                       Diary No.:
                    </Heading>

                </div>
                <div className="col-md-4">
                    <Form.Control  type="text" name="Dairy_No" value={Dairy_No} onChange={handleFormValues} />
                </div>
            </Form.Group>
            <Form.Group as={Row}>
                <div className="col-md-2 p-0">
                <Heading className="xhsh ">
                     Department: 
                    </Heading>
                </div>
                <div className="col-md-10">
                
             
   
    <Form.Control as="select" onChange={departSelector} ref={selectNode} custom>
      <option value="">--All Departments--</option>
    {
        departments.length ? departments.map(d=>
             <option key={d.id} value={d.id} name="Dept_id"   >{d.Name}</option>):""
    }
    </Form.Control>

                </div>
            </Form.Group>
            <div className="text-center">
                    {
                        msg.error ? <Paragraph className="text-danger">{msg.error}</Paragraph>:""
                    }
            </div>
            <Form.Group as={Row} className="d-flex justify-content-center mt-5">
                <StyledButton type="submit" className="styled-btn-green mr-5">
                    Search
                </StyledButton>
                <StyledButton type="button" style={{borderRadius:"1rem",fontSize:"1.4rem"}} onClick={clearSearch}>
                    Clear
                </StyledButton>
            </Form.Group>
        </Form>
    )
}
var mapState = (state) =>({
    departments:state.departments
    
})
export default connect(mapState)(SearchTenderForm)
