import React from "react";
import Heading from "../../Components/Heading/Heading";
import Paragraph from "../../Components/Paragraph/Paragraph";
import "./About.css";
const AboutPeshawar = ({setLoad}) => {
  React.useEffect(()=>{
    setLoad(false)
},[])
  return (
    <div>
      <Paragraph>
        Peshawar (help info) ( Pashto: Pekhawar/Peshawar, Hindko. Pishor, Urdu.
        .,4541,j), is the capital of the Khyber Pakhtunkhwa[2] and the
        administrative centre (but not the capital) for the Federally
        Administered Tribal Areas of Pakistan.[3] The Kushan king Kanishka,
        moved the capital from Pushkalavati (now called Charsadda in the
        Peshawar valley) to Purushapura in the 2nd century CE.141 The current
        name "Peshawar" may denve from the Sanskrit Purushapura[5] (meaning
        "city of men") and is known as Pekhawar or Peshawar in Pashto and Pishor
        in Hindko. The area originally belonged to Gandhara and the eastern
        Iranian tribes of Scythian origin and later became part of the Kushan
        Empire. It gave its name to the Peshwari naan bread, one of the diverse
        types of naan common in the curry houses of Great Britain. Bnefly it
        also witnessed some Greek influence after which it saw the Arab conquest
        and rise of Islam. Today it is one of the prime cities of Pakistan west
        of the river Indus.
      </Paragraph>
      <Paragraph className="text-center my-5" fontSize={30}>
        PESHAWAR AT GLANCE
      </Paragraph>
      <div className="p-table row m-0">
        <div className="col-md-6">
          <Paragraph>Province:</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>Khyber Pakhtunkhwa:</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>Capital:</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>Peshawar</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>Area:</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>
            Total 1,257 km<sup>2</sup> (485.3 sq mi)
          </Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>Population (1998):</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>Total 2,019,118</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>Density</Paragraph>
        </div>
        <div className="col-md-6">
          <Paragraph>
            1,606/km<sup>2</sup>(4,160.3/sq mi)
          </Paragraph>
        </div>
      </div>
      <Heading fontSize={30} className="hhh my-5">
        History
      </Heading>
      <Paragraph>
        History of Peshawar Peshawar is now officially recognised as being one
        of the Oldest Living Cities in Asia. Its history and culture has
        continued uninterrupted since several centuries. This fact was confirmed
        by the discovery of silver punch-marked coins from the Government House
        in 1906-07 and the ongoing excavation at Gor Khalil which is the deepest
        and widest in the world.Being among the most ancient cities of the
        region between Central, South, and West Asia, Peshawar has for centuries
        been a centre of trade between Afghanistan, South Asia, Central Asia and
        the Middle East. As an ancient centre of learning, the 2nd century BCE.
        Bakhshali Manuscript used in the Bakhshali approximation was found
        nearby. Peshawar was a major centre of Buddhist learning until the 10th
        century. As an indication of its importance, Pe-shawar was also the site
        of Kanishka's Great Stupa which housed relics of Gautama Buddha, and was
        widely considered to be the tallest building in the world at the time of
        its construction
      </Paragraph>
    </div>
  );
};

export default AboutPeshawar;
