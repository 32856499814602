import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {getDepartmentName} from "../../Utility"
const DepartmentName = ({departments,dptId}) => {
    const [dpt,setDpt]  = useState("");
    useEffect(()=>{
        
        if(dptId && departments.length){
            
            var temp = getDepartmentName(dptId,departments);
          
            setDpt(temp)
        }
    },[dptId,departments])
    return (
        <>
            {dpt}
        </>
    )
}
var mapState = (state)=>({
    departments:state.departments
})
export default connect(mapState)(DepartmentName)
