import React, { useEffect, useState } from 'react'
import { fetchEvents } from '../../Redux/Actions/Actions'
import EventListItem from '../EventListItem/EventListItem'
import Heading from '../Heading/Heading'
import Loader from '../Loader/Loader'

const Events = () => {
    const [events,setEvents] = useState([])
    const [load,setLoad] = useState(true)

  
  
    useEffect(async ()=>{
      var arr = await fetchEvents()
      setEvents(arr);
      setLoad(false)
    },[])
    return (
        <div className="">
              <div className="side-heading">
                <Heading className="text-white">
                  Events
                </Heading>
            </div>
            <div className="news-list position-relative">
                {
                    events.length ? events.map(e => <EventListItem key={e.id} event={e}/>):""
                }
                
                {
                load ? <Loader/> :""
            }
            </div>
           
        </div>
    )
}

export default Events
