import { faGreaterThan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Heading from '../Heading/Heading'
import Paragraph from '../Paragraph/Paragraph'
import "./QuickNav.css"
const QuickNav = () => {
    return (
        <div>
             <div className="side-heading">
                <Heading className="text-white">
                 Quick Navigations
                </Heading>
            </div>
            <div className="quick-nav-list">
                <div className="quick-nav-list-item">
                <Link to="/about">
                <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} /> About KDA
                    </Paragraph>
                </Link>
                </div>
                <div className="quick-nav-list-item">
                  <Link to="/about/directorates">
                  <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} /> Directorates
                    </Paragraph>
                  </Link>

                </div>
                <div className="quick-nav-list-item">
                   <Link to="/tenders/1">
                   <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} />  KDA Tenders
                    </Paragraph>
                   </Link>
                </div>
           
                <div className="quick-nav-list-item">
                   <Link to="/projects/all/1">
                   <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} />  Projects
                    </Paragraph>
                   </Link>
                </div>
             
                <div className="quick-nav-list-item">
                   <Link to="/about/events">
                   <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} />  Events
                    </Paragraph>
                   </Link>
                </div>
                <div className="quick-nav-list-item">
                   <Link to="/advertisements/1">
                   <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} />  Advertisement
                    </Paragraph>
                   </Link>
                </div>
                <div className="quick-nav-list-item">
                   <Link to="/about/downloads/1">
                   <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} />  Downloads
                    </Paragraph>
                   </Link>
                </div>
                <div className="quick-nav-list-item">
                   <Link to="/contact">
                   <Paragraph className="q-link">
                        <FontAwesomeIcon  icon={faGreaterThan} />  Complaint Cell
                    </Paragraph>
                   </Link>
                </div>
            
                <div className="quick-nav-list-item">
                   <Link to="/about/picture_gallery">
                   <Paragraph className="">
                        <FontAwesomeIcon  icon={faGreaterThan} />  Picture Gallery
                    </Paragraph>
                   </Link>
                </div>
            </div>
        </div>
    )
}

export default QuickNav
